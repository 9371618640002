import http from '@/services/http/app-http-driver'

export default {
  globalIndividualLeaderboard(params = {}) {
    return http({ url: '/individual-leaderboard', method: 'get', params })
  },
  globalTeamLeaderboard(params = {}) {
    return http({ url: '/team-leaderboard', method: 'get', params })
  },
  globalTeamAwards() {
    return http({ url: '/team-leaderboard/awards', method: 'get' })
  },
  campaignIndividualLeaderboard(id, params = {}) {
    return http({ url: `/campaigns/${id}/individual-leaderboard`, method: 'get', params })
  },
  campaignTeamLeaderboard(id, params = {}) {
    return http({ url: `/campaigns/${id}/team-leaderboard`, method: 'get', params })
  },  
}
